<template>
  <div>
    <PageHeader
      :title="$t('backoffice.modelo_tributario')"
      :items="breadcrumb"
    />
    <!-- Filtro -->
    <b-card class="mb-3 mt-3">
      <h4 style="text-align: center">Filtrar</h4>
      <div class="filtros">
        <div class="form-group">
          <label for="filtroCnpj">CNPJ da Distribuidora</label>
          <input
            type="text"
            class="form-control"
            v-model="filtroCnpj"
            @input="filtrarModelos"
            v-mask="'##.###.###/####-##'"
          />
        </div>
        <div class="form-group">
          <label for="filtroDistribuidora">Nome da Distribuidora</label>
          <input
            type="text"
            class="form-control"
            v-model="filtroDistribuidora"
            @input="filtrarModelos"
          />
        </div>

        <!-- Mês Referência -->
        <div class="form-group">
          <label for="filtroMesRef">Mês Referência</label>
          <b-form-select
            id="filtroMesRef"
            v-model="filtroMesRef"
            :options="meses"
            @change="filtrarModelos"
          ></b-form-select>
        </div>

        <!-- Ano Referência -->
        <div class="form-group">
          <label for="filtroAnoRef">Ano Referência</label>
          <b-form-input
            id="filtroAnoRef"
            type="number"
            v-model="filtroAnoRef"
            @change="filtrarModelos"
          ></b-form-input>
        </div>
      </div>
      <div>
        <!-- Botão para Cadastrar Modelo Tributário -->
        <b-button variant="success" @click="cadastrarModeloTributario"
          >Cadastrar Modelo Tributário</b-button
        >
      </div>
    </b-card>

    <!-- Tabela de Modelos Tributários -->
    <b-card>
      <h4 class="titulo">Modelos Tributários</h4>
      <b-table
        :items="modelosFiltrados"
        :fields="tableFields"
        :striped="true"
        :hover="false"
        :bordered="false"
        :borderless="false"
        responsive="sm"
      >
        <template v-slot:cell(id)="row">
          <a class="underline-hover" @click="redirectPage(row.item)">{{
            row.value
          }}</a>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import Vue from 'vue';
import VueMask from 'v-mask';
import 'vue-toast-notification/dist/theme-sugar.css';
import 'dayjs/locale/pt-br';
import PageHeader from '@/components/page-header';
import 'moment/locale/pt-br';
import meses from '@/utils/meses';

import { listAll } from './modeloTributario.service';

Vue.use(VueMask);

export default {
  name: 'ModelosTributarios',
  data() {
    return {
      modelos: [],
      filtroCnpj: '',
      filtroDistribuidora: '',
      meses: meses(),
      filtroMesRef: 'Todos',
      filtroAnoRef: '',
      modelosFiltrados: [],
      tableFields: [
        { key: 'id', label: 'ID', sortable: true },
        {
          key: 'distribuidora.cnpjDistribuidora',
          label: 'CNPJ',
          sortable: true
        },
        {
          key: 'distribuidora.nomeDistribuidora',
          label: 'Distribuidora',
          sortable: true
        },
        { key: 'mesRef', label: 'Mês de Referência', sortable: true }
      ],
      breadcrumb: [
        {
          text: 'Backoffice',
          href: '#'
        },
        {
          text: 'Modelos Tributários',
          active: true
        }
      ]
    };
  },
  components: {
    PageHeader
  },
  methods: {
    buscarModelos() {
      listAll().then((response) => {
        if (response.status) {
          this.modelos = response.content;
          this.filtrarModelos();
        } else {
          console.error('Erro ao buscar modelos: ', response.msg);
        }
      });
    },
    filtrarModelos() {
      this.modelosFiltrados = this.modelos.filter((modelo) => {
        const cnpjMatch =
          this.filtroCnpj === '' ||
          modelo.distribuidora.cnpjDistribuidora.includes(this.filtroCnpj);
        const distribuidoraMatch = modelo.distribuidora.nomeDistribuidora
          .toLowerCase()
          .includes(this.filtroDistribuidora.toLowerCase());
        const mesRefMatch =
          this.filtroMesRef === 'Todos' ||
          modelo.mesRef.includes(this.filtroMesRef);
        const anoRefMatch =
          this.filtroAnoRef === '' ||
          modelo.mesRef.split('-')[0] === this.filtroAnoRef;

        return cnpjMatch && distribuidoraMatch && mesRefMatch && anoRefMatch;
      });
      console.log(this.modelosFiltrados);
    },
    cadastrarModeloTributario() {
      this.$router.push({
        path: '/edit-modelo-tributario',
        query: { modelos: JSON.stringify(this.modelos) }
      });
      console.log(JSON.stringify(this.modelos));
    },
    redirectPage(modelo) {
      this.$router.push({
        path: '/edit-modelo-tributario',
        query: {
          modelo: JSON.stringify(modelo),
          modelos: JSON.stringify(this.modelos)
        }
      });
      const modeloString = JSON.stringify(modelo);
      console.log(modeloString);
    }
  },
  mounted() {
    this.buscarModelos();
  }
};
</script>

<style>
.filtros {
  display: flex;
}

.form-group {
  flex-grow: 1;
  padding: 15px;
}

.titulo {
  text-align: center;
}

.underline-hover:hover {
  text-decoration: underline !important;
}
</style>
